<template lang="html">
    <div class="container-fluid p-5">
        <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12">

                            </div>
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-md-3 mt-1">
                                        <input onfocus="(this.type='date')" placeholder="Date" v-model="filter.date"
                                            class="form-control">
                                    </div>
                                    <div class="col-md-3 mt-1">
                                        <input type="text" placeholder="Receive No" v-model="filter.receiveNo"
                                            class="form-control">
                                    </div>
                                    <div class="col-md-6 mt-1">
                                        <button class="btn btn-primary  mr-1 fbutton" @click="search()"><i
                                                class="fas fa-search"></i> Search</button>
                                        <button class="btn btn-info  mr-1 fbutton" @click="reset()"><i
                                                class="fas fa-sync"></i> Reset</button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 mt-2">
                                <div class="table-responsive">
                                    <table class="table table-light">
                                        <thead>
                                            <tr>
                                                <th v-for="(value, key) in columns"
                                                    v-bind:class="{ 'asc': filter.sort == key && filter.reverse == 'desc', 'desc': filter.sort == key && filter.reverse == 'asc' }">
                                                    <a href="#" @click="sortBy(key)" class="text-capitalize tbl_th_a">
                                                        {{ value }}
                                                    </a>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(data, index) in dataList">
                                                <td>{{ data.receive_no }}</td>
                                                <td>{{ data.order_count }}</td>
                                                <td>{{ data.total_price }}</td>
                                                <td>{{ data.commissionable_amount }}</td>
                                                <td>{{ data.commission_amount }}</td>
                                                <td>{{ data.receive_date }}</td>
                                                <!-- <td style="padding: 0px;">
                                                    <button class="btn btn-option text-primary"
                                                        @click="showDetail(data)"><i class="fas fa-eye"></i></button>
                                                </td> -->
                                            </tr>
                                            <tr v-if="dataList.length <= 0">
                                                <td colspan="7" class="text-center">Empty List</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <nav v-if="dataList.length > 0" class="table_info">
                                        Showing {{ metaData.from }} to {{ metaData.to }} of {{ metaData.total }} entries
                                    </nav>
                                    <nav class="paging_simple_numbers">
                                        <ul class="pagination pagination-md">
                                            <li class="page-item"
                                                :class="{ 'disabled': metaData.prev_page_url === null }">
                                                <a href="#" class="page-link" @click="next(metaData.current_page - 1)">
                                                    &laquo;
                                                </a>
                                            </li>
                                            <li class="page-item" v-for="page in metaData.last_page" :key="page"
                                                :class="{ 'active': metaData.current_page === page }"
                                                v-if="Math.abs(page - metaData.current_page) < 3 || page == metaData.total - 1 || page == 0">
                                                <a href="#" @click.prevent="next(page)" class="page-link">
                                                    {{ page }}
                                                </a>
                                            </li>
                                            <li class="page-item"
                                                :class="{ 'disabled': metaData.current_page === metaData.last_page }">
                                                <a href="#" class="page-link" @click="next(metaData.current_page + 1)">
                                                    &raquo;
                                                </a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-modal id="bv-modal-delete" ref="my-modal" title="Delete" hide-footer>
            <p class="my-4">Are you sure to delete ? </p>
            <b-button class="mt-3 btn-danger" block @click="deleteItem()">Delete</b-button>
        </b-modal>
    </div>
</template>

<script lang="js">
import {
    mapState,
    mapActions
} from 'vuex'
import env from '../enviorments.js'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css';
export default {
    name: 'orderList',
    metaInfo: {
        title: "Order List",
        titleTemplate: "%s ← SHRE Receive List",
    },
    components: {
        Loading
    },
    data() {
        return {
            env,
            isLoading: false,
            fullPage: true,
            alert: "",
            id: '',
            dataList: [],
            dashboard: [],
            errorList: null,
            columns: {
                receive_no: "Receive No",
                order_count: "Order Count",
                total_price: "Total Price",
                commissionable_amount: "Commissionable",
                commission_amount: "Commission",
                receive_date: "Date",
            },
            filter: {
                page: 1,
                orderBy: "desc",
                sortKey: "created_at",
                limit: 10,
                date: "",
                receiveNo: "",
            },
            metaData: {
                last_page: null,
                current_page: 1,
                from: null,
                to: null,
                total: null,
                prev_page_url: null
            }
        }
    },
    watch: {
        '$route'(to, from) {
        }
    },
    computed: {},
    methods: {
        ...mapActions({
            agentReceiveListAction: 'agentReceiveListAction',
            dashboardAction: 'dashboardAction',
        }),
        sortBy(column) {
            this.filter.sort = column
            if (this.filter.sort == column) {
                if (this.filter.reverse == "asc") {
                    this.filter.reverse = "desc"
                } else if (this.filter.reverse == "desc") {
                    this.filter.reverse = "asc"
                }
            } else {
                this.filter.reverse = "asc"
            }
            this.fetchData()
        },
        async search() {
            this.filter.page = 1
            this.fetchData()
        },
        async fetchData() {
            this.isLoading = true
            let option = this.filter
            await this.agentReceiveListAction({
                ...option
            }).then(res => {
                if (res.status == "success") {
                    this.dataList = res.data.data
                    this.metaData.last_page = res.data.meta.last_page
                    this.metaData.current_page = res.data.meta.current_page
                    this.metaData.prev_page_url = res.data.links.prev
                    this.metaData.from = res.data.meta.from
                    this.metaData.to = res.data.meta.to
                    this.metaData.total = res.data.meta.total
                    this.$router.replace({
                        path: 'receive-list', query: {
                            page: this.filter.page, orderBy: this.filter.orderBy, sortKey: this.filter.sortKey, limit: this.filter.limit, fromDate: this.filter.fromDate, toDate: this.filter.toDate,
                            status: this.filter.status, userId: this.filter.userId, email: this.filter.email, orderNo: this.filter.orderNo
                        }
                    }).catch(() => { })
                    this.isLoading = false;
                }
            }).catch(err => this.isLoading = true)
        },
        next: function (page) {
            this.filter.page = page
            this.fetchData();
        },
        reset() {
            this.$router.replace({ path: 'receive-list', query: { page: 1, orderBy: 'desc', sortKey: 'created_at', limit: this.filter.limit, fromDate: '', toDate: '', status: '', userId: '', email: '', orderNo: '' } }).catch(() => { })
            this.$router.go()
        },
        deleteData(data) {
            this.id = data.id
        },
        async dashboardDetail() {
            this.isLoading = true;
            await this.dashboardAction().then(res => {
                if (res.status == "success") {
                    this.dashboard = res.data
                    this.isLoading = false
                }
            }).catch(err => this.isLoading = true)
        }
    },
    filters: {
        subStr: function (string) {
            let stLength = string.length
            if (stLength > 30) {
                return string.substring(0, 30) + '...';
            } else {
                return string
            }
        }
    },
    mounted() {
        this.filter.page = this.$route.query.page
        this.filter.reverse = this.$route.query.reverse
        this.filter.sort = this.$route.query.sort
        this.filter.date = this.$route.query.date
        this.filter.receiveNo = this.$route.query.receiveNo
        this.fetchData()
    }
}
</script>

<style scoped lang="scss">
.action {
    border: none;
    background: none;
    font-size: 24px;
}

.mass-btn {
    width: 100%;
}

.fbutton {
    width: 20%;
}
</style>